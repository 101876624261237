@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    @layer responsive {
      /* Hide scrollbar for Chrome, Safari, and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }
  
  
      /* Hide scrollbar for IE, Edge, and Firefox */
      .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }


  * {
    margin: 0;
    padding: 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p {
    cursor: default;
  }
  
  .scrollbar-hidden::-webkit-scrollbar {
    width: 0;
  }
  
  .custome-height__profile {
    height: calc(100vh - 76px);
  }
  
  .min-custome-height__profile {
    min-height: calc(100vh - 76px);
  }
  
  .custome--progress-bar progress::-webkit-progress-bar {
    width: 100%;
    border-radius: 10px;
  }
  progress::-webkit-progress-bar {
    background-color: #343434;
    border-radius: 10px;
  }
  progress::-webkit-progress-value {
    background-color: #aa933e;
    border-radius: 10px;
  }
  
  .limited-paragraph {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-bottom: 10px; /* Adjust as needed */
  }
  
  .limited-paragraph.expanded {
    -webkit-line-clamp: unset;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .custom-datepicker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .custom-datepicker-input {
    /* Add your custom styles for the date picker input here */
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: 2px solid #535354;
    border-radius: 12px;
    padding-left: 13px;
    outline: none;
  
    background-color: #242525;
    color: white;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
  }
  
  .cutome-datepicker {
    position: relative;
    width: 100%;
  }
  
  .custome-arrow__container {
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* background-color: red; */
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
  
    pointer-events: none;
  }
  
  .arrow {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 10px solid #8c8c8c;
  }
  
  /* Datepicker custimazations  */
  .react-datepicker {
    font-size: 15px !important;
    background-color: #242525 !important;
    color: white !important;
    border: 2px solid #535354 !important;
  }
  .react-datepicker__day {
    font-size: 15px !important;
    background-color: #242525 !important;
    color: white !important;
    /* border: 2px solid #535354 !important; */
  }
  
  .react-datepicker__header {
    text-align: center;
    background-color: #535354 !important;
    border-bottom: 1px solid transparent !important;
    padding: 8px 0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    position: relative;
  }
  
  .react-datepicker__current-month {
    color: white !important;
  }
  
  .react-datepicker__day-name {
    color: white !important;
    font-size: 15px;
    font-weight: bold;
  }
  
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    fill: #535354 !important;
    color: #535354 !important;
    stroke: #535354 !important;
  }
  
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    fill: #242525 !important;
    color: #242525 !important;
    stroke: #535354 !important;
  }
  
  .react-datepicker__month-text--keyboard-selected {
    background-color: #535354 !important;
    font-weight: bolder;
    color: white !important;
    font-size: 15px;
  }
  
  .react-datepicker__day--selected {
    background-color: #535354 !important;
    font-weight: bolder;
    color: white !important;
    font-size: 15px;
  }
  
  .react-datepicker__month-text:hover {
    background-color: #535354 !important;
  }
  
  .react-datepicker__day:hover {
    background-color: #535354 !important;
  }
  
  .react-datepicker__month-text--disabled {
    opacity: 0.3;
  }
  
  .react-datepicker__day--disabled {
    opacity: 0.3;
  }
  
  .react-datepicker-popper svg {
    left: 10px !important;
  }
  
  .react-datepicker-year-header {
    color: white !important;
  }
  
  .input-checkbox--style input[type="checkbox"] {
    accent-color: #aa933e;
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
  
  /* new checkbox css */
  /* This css is for normalizing styles. You can skip this. */
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .new {
    padding: 50px;
  }
  
  .form-group {
    display: block;
    /* margin-bottom: 15px; */
  }
  
  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
  }
  
  .form-group label:before {
    content: "";
    /* -webkit-appearance: none; */
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 2px solid #535354;
    border-radius: 6px;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
    /* padding: 10px; */
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  
  .form-group input:checked + label:before {
    background-color: #aa933e;
    border: none; /* Change the background color for checked state */
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-200%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-200%);
    }
  }
  
  .popup-animation {
    animation: slideIn 0.5s ease forwards;
  }
  
  .popup-closeanimation {
    animation: slideOut 0.5s ease forwards;
  }
  
  .circle-progress {
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  .svg-pi-indicator--spinner {
    animation: spinner 0.75s linear infinite;
    transform-origin: center;
  }
  
  @keyframes spinner {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 3px;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #aa933e;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 8px 2px #aa933e;
  }
  
  .pdf-container {
    height: 500px;
    width: 100%;
  }
  
  @keyframes dropTop {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  